body {
  background-color: #1b1a17;
  color: #f0e3ca;
}

.headline {
  color: #ff8303;
}

.titles {
  color: #ff8303;
}

.flex-container {
  display: flex;
  justify-content: center;
}

.text {
  color: #f0e3ca;
}

.padding-sides {
  padding: 0 5%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 5%;
}

.react-player iframe {
  border-radius: 10px;
}

.react-player__preview {
  border-radius: 10px;
}

.App {
  padding: 5%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-layout {
  display: grid;
  grid-gap: 1rem;
}

@media (min-width: 768px) {
  .first-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .about-section {
    margin: 2rem 0;
  }
  .text {
    font-size: 1.35rem;
  }
  .headline {
    margin: 0;
  }
  .button-cta {
    display: none;
  }
  .form-section {
    margin: 2rem 0;
  }
  .form-layout {
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
}
